import React, { useEffect, useRef, useState } from "react";
import "../styles/style.css";
import Footer from "./Footer";

const About = () => {
  const [scrolled, setScrolled] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [activeSubMenu, setActiveSubMenu] = useState(null);

  const toggleMenu = () => {
    setIsActive(!isActive);
  };

  const handleSubMenuClick = (index) => (e) => {
    e.preventDefault();
    setActiveSubMenu(activeSubMenu === index ? null : index);
  };

  // Function to handle scroll event
  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuToggle = () => setIsMenuOpen(!isMenuOpen);

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const [showAnimation, setShowAnimation] = useState(false);
  const elementRef = useRef(null);

  useEffect(() => {
    const checkPosition = () => {
      if (!elementRef.current) {
        // Element is not yet available in the DOM, exit early
        return;
      }

      const element = elementRef.current;
      const elementPosition = element.getBoundingClientRect().top;
      const viewportHeight = window.innerHeight;

      // Example: Trigger animation when element is in the viewport
      if (elementPosition < viewportHeight) {
        setShowAnimation(true);
      } else {
        setShowAnimation(false);
      }
    };

    // Add scroll event listener
    window.addEventListener("scroll", checkPosition);

    // Check position initially in case the element is already in view on initial load
    checkPosition();

    // Clean up
    return () => window.removeEventListener("scroll", checkPosition);
  }, []);

  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [imageLoadError, setImageLoadError] = useState(false);

  const openLightbox = (images) => {
    setCurrentImageIndex(images);
    setIsLightboxOpen(true);
    setImageLoadError(false);
  };

  const handleImageError = () => {
    setImageLoadError(true);
  };

  // const [photoIndex, setPhotoIndex] = useState(0);
  // const [isOpen, setIsOpen] = useState(false);

  const [isVisible, setIsVisible] = useState(false);

  // Function to check the scroll position
  const checkScrollTop = () => {
    if (!isVisible && window.pageYOffset > 400) {
      setIsVisible(true);
    } else if (isVisible && window.pageYOffset <= 400) {
      setIsVisible(false);
    }
  };

  // Function to scroll to top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);
    return () => window.removeEventListener("scroll", checkScrollTop);
  });

  return (
    <div>
      <div>
        {/* MOBILE MENU */}
        {isActive && (
          <div className="mask-nav-2 is-active">
            {/* MENU */}
            <nav className="navbar navbar-2 nav-mobile">
              <div className="nav-holder nav-holder-2">
                <ul id="menu-menu-2" className="menu-nav-2">
                  <li
                    className={`menu-item  ${
                      activeSubMenu === 1 ? "menu-open" : ""
                    }`}
                  >
                    <a href="/">Home</a>
                  </li>
                  <li className="menu-item">
                    <a href="/menu">Menu</a>
                  </li>

                  <li className="menu-item">
                    <a href="/reservation">Reservation</a>
                  </li>
                  <li className="menu-item ">
                    <a href="/gallery">Gallery</a>
                  </li>

                  <li className="menu-item ">
                    <a href="/contact">Contact</a>
                  </li>
                </ul>
              </div>
            </nav>
            {/* /MENU */}
          </div>
        )}
        {/* /MOBILE MENU */}
        {/* HEADER */}
        <header id="header-1">
          <div className="headerWrap-1">
            <nav className="navbar-1">
              {/* TOP LEFT PAGE TEXT  */}
              <div className="top-location">
                <span className="info-txt">
                  {/* <a href="index.html">
                    <img
                      className="img-fluid"
                      src="images/PremierChef.png"
                      alt="logo"
                      style={{ height: "150px", filter: "invert(1)" }}
                    />
                  </a>{" "} */}
                  <a href="/" className="navbar-brand p-0">
                    <h1>Premier Chef</h1>
                  </a>
                </span>

                {/* <span className="info-txt">New York 70250</span>    */}
              </div>
              {/* TOP RIGHT PAGE TEXT  */}
              <div className="book-now">
                <ul className="header-social">
                  {/* <li>
                    <a>
                      <GoogleTranslate/>
                    </a>
                  </li> */}
                  <li>
                    <a
                      className="header-social-facebook"
                      href="https://web.facebook.com/premierchefcatering"
                      target="_blank"
                    >
                      <i className="fab fa-facebook" />
                    </a>
                  </li>
                  <li>
                    <a
                      className="header-social-twitter"
                      href="#"
                      target="_blank"
                    >
                      <i className="fab fa-line" />
                    </a>
                  </li>
                  <li>
                    <a
                      className="header-social-instagram"
                      href="https://www.instagram.com/premierchefcatering/"
                      target="_blank"
                    >
                      <i className="fab fa-instagram" />
                    </a>
                  </li>
                </ul>
              </div>
              {/* <div className="book-now">
                <span className="info-txt">Book Now</span>
                <span className="info-txt">+1-222-333-444</span>
              </div> */}
              {/* MOBILE BUTTON NAV  */}

              <div
                className={`nav-button-holder nav-btn-mobile inactive ${
                  isActive ? "active" : "inactive"
                }`}
                onClick={toggleMenu}
                style={{ marginTop: "5px" }}
              >
                {/* <span className="menu-txt" style={{ fontSize: "18px" }}>
                  MENU
                </span> */}
                <button className="nav-button">
                  <span
                    className="fa fa-bars"
                    style={{ color: "white", fontSize: "30px" }}
                  />
                </button>
              </div>
              <div className="nav-content-1">
                {/* LOGO */}
                <div className="logo-1">
                  <a href="/" className="navbar-brand p-0">
                    <h1>Premier Chef</h1>
                  </a>
                </div>
                {/* MENU */}
                <div className="nav-holder nav-holder-1 nav-holder-desktop">
                  <ul id="menu-menu-1" className="menu-nav menu-nav-1 mr-4">
                    <li className="menu-item ">
                      <a href="/">Home</a>
                    </li>
                    <li className="menu-item ">
                      <a href="/menu" onMouseEnter={handleMouseEnter}>
                        Menu
                      </a>
                    </li>
                    {/* menu-item-has-children  */}
                    <li className="menu-item">
                      <a href="/reservation">Reservation</a>
                    </li>
                    <li className="menu-item">
                      <a href="/gallery">Gallery</a>
                    </li>

                    <li className="menu-item ">
                      <a href="/contact">Contact</a>
                    </li>
                  </ul>
                </div>
                {/* /MENU */}
              </div>
            </nav>
          </div>
          {/*headerWrap*/}
        </header>
        {/* /HEADER */}
      </div>

      {isHovered && (
        <div
          ref={elementRef}
          className={`menu animated ${
            showAnimation ? "animatedFadeInUp fadeInUp" : ""
          }`}
          onMouseLeave={handleMouseLeave}
        >
          <nav className={`menu-content ${isMenuOpen ? "active" : ""}`}>
            <div className="menu-holder menu-1col header-menu-accordion">
              <h4>
                <span className="header-menu-title">HOT COFFEES</span>
              </h4>
              <div className="header-menu-post clearfix">
                <div className="headermenu-img">
                  <img
                    width={400}
                    height={400}
                    src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/f12bc8af498d45ed92c5d6f1dac64062_result-150x150.jpg"
                    alt="Tomato Bruschetta"
                    title="Tomato Bruschetta"
                  />
                </div>

                <div className="header-menu-post-desc">
                  <h4>
                    <span className="menu-title">CAFE AMERICANO</span>
                    <span className="menu-dots"></span>
                    <span className="menu-price">$9.00</span>
                  </h4>
                  <div className="header-menu-text">
                    {" "}
                    Espresso shots topped with hot water{" "}
                  </div>
                </div>
              </div>
              <div className="header-menu-post clearfix">
                <div className="headermenu-img">
                  <img
                    width="400"
                    height="400"
                    src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/5c515339667943ce84dc56effdf5fc1b_result-150x150.jpg"
                    alt="Tomato Bruschetta"
                    title="Tomato Bruschetta"
                  />
                </div>
                <div className="header-menu-post-desc">
                  <h4>
                    <span className="menu-title">CAPPUCCINO</span>
                    <span className="menu-dots"></span>
                    <span className="menu-price">$9.00</span>
                  </h4>
                  <div className="header-menu-text">
                    {" "}
                    Dark espresso lies in wait under milk foam{" "}
                  </div>
                </div>
              </div>
              <div className="header-menu-post clearfix">
                <div className="headermenu-img">
                  <img
                    width="400"
                    height="400"
                    src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/58db701349cb48738069e8c912e2b3ac_result-150x150.jpg"
                    alt="Tomato Bruschetta"
                    title="Tomato Bruschetta"
                  />
                </div>
                <div className="header-menu-post-desc">
                  <h4>
                    <span className="menu-title">CARAMEL MACCHIATO</span>
                    <span className="menu-dots"></span>
                    <span className="menu-price">$9.00</span>
                  </h4>
                  <div className="header-menu-text">
                    {" "}
                    Freshly steamed milk with vanilla-flavored syrup{" "}
                  </div>
                </div>
              </div>
              <div className="header-menu-post clearfix">
                <div className="headermenu-img">
                  <img
                    width="400"
                    height="400"
                    src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/f4ec500b34624242b15c2d29e53f3c48_result-150x150.jpg"
                    alt="Tomato Bruschetta"
                    title="Tomato Bruschetta"
                  />
                </div>
                <div className="header-menu-post-desc">
                  <h4>
                    <span className="menu-title">CAFFE MOCHA</span>
                    <span className="menu-dots"></span>
                    <span className="menu-price">$7.00</span>
                  </h4>
                  <div className="header-menu-text">
                    {" "}
                    Full-bodied espresso combined with mocha{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className="menu-holder menu-1col header-menu-accordion">
              <h4>
                <span className="header-menu-title">COLD COFFEES</span>
              </h4>
              <div className="header-menu-post clearfix">
                <div className="headermenu-img">
                  <img
                    width={400}
                    height={400}
                    src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/f12bc8af498d45ed92c5d6f1dac64062_result-150x150.jpg"
                    alt="Tomato Bruschetta"
                    title="Tomato Bruschetta"
                  />
                </div>

                <div className="header-menu-post-desc">
                  <h4>
                    <span className="menu-title">CAFE AMERICANO</span>
                    <span className="menu-dots"></span>
                    <span className="menu-price">$9.00</span>
                  </h4>
                  <div className="header-menu-text">
                    {" "}
                    Espresso shots topped with hot water{" "}
                  </div>
                </div>
              </div>
              <div className="header-menu-post clearfix">
                <div className="headermenu-img">
                  <img
                    width="400"
                    height="400"
                    src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/5c515339667943ce84dc56effdf5fc1b_result-150x150.jpg"
                    alt="Tomato Bruschetta"
                    title="Tomato Bruschetta"
                  />
                </div>
                <div className="header-menu-post-desc">
                  <h4>
                    <span className="menu-title">CAPPUCCINO</span>
                    <span className="menu-dots"></span>
                    <span className="menu-price">$9.00</span>
                  </h4>
                  <div className="header-menu-text">
                    {" "}
                    Dark espresso lies in wait under milk foam{" "}
                  </div>
                </div>
              </div>
              <div className="header-menu-post clearfix">
                <div className="headermenu-img">
                  <img
                    width="400"
                    height="400"
                    src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/58db701349cb48738069e8c912e2b3ac_result-150x150.jpg"
                    alt="Tomato Bruschetta"
                    title="Tomato Bruschetta"
                  />
                </div>
                <div className="header-menu-post-desc">
                  <h4>
                    <span className="menu-title">CARAMEL MACCHIATO</span>
                    <span className="menu-dots"></span>
                    <span className="menu-price">$9.00</span>
                  </h4>
                  <div className="header-menu-text">
                    {" "}
                    Freshly steamed milk with vanilla-flavored syrup{" "}
                  </div>
                </div>
              </div>
              <div className="header-menu-post clearfix">
                <div className="headermenu-img">
                  <img
                    width="400"
                    height="400"
                    src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/f4ec500b34624242b15c2d29e53f3c48_result-150x150.jpg"
                    alt="Tomato Bruschetta"
                    title="Tomato Bruschetta"
                  />
                </div>
                <div className="header-menu-post-desc">
                  <h4>
                    <span className="menu-title">CAFFE MOCHA</span>
                    <span className="menu-dots"></span>
                    <span className="menu-price">$7.00</span>
                  </h4>
                  <div className="header-menu-text">
                    {" "}
                    Full-bodied espresso combined with mocha{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className="menu-holder menu-1col header-menu-accordion">
              <h4>
                <span className="header-menu-title">BAKERY</span>
              </h4>
              <div className="header-menu-post clearfix">
                <div className="headermenu-img">
                  <img
                    width={400}
                    height={400}
                    src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/f12bc8af498d45ed92c5d6f1dac64062_result-150x150.jpg"
                    alt="Tomato Bruschetta"
                    title="Tomato Bruschetta"
                  />
                </div>

                <div className="header-menu-post-desc">
                  <h4>
                    <span className="menu-title">CAFE AMERICANO</span>
                    <span className="menu-dots"></span>
                    <span className="menu-price">$9.00</span>
                  </h4>
                  <div className="header-menu-text">
                    {" "}
                    Espresso shots topped with hot water{" "}
                  </div>
                </div>
              </div>
              <div className="header-menu-post clearfix">
                <div className="headermenu-img">
                  <img
                    width="400"
                    height="400"
                    src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/5c515339667943ce84dc56effdf5fc1b_result-150x150.jpg"
                    alt="Tomato Bruschetta"
                    title="Tomato Bruschetta"
                  />
                </div>
                <div className="header-menu-post-desc">
                  <h4>
                    <span className="menu-title">CAPPUCCINO</span>
                    <span className="menu-dots"></span>
                    <span className="menu-price">$9.00</span>
                  </h4>
                  <div className="header-menu-text">
                    {" "}
                    Dark espresso lies in wait under milk foam{" "}
                  </div>
                </div>
              </div>
              <div className="header-menu-post clearfix">
                <div className="headermenu-img">
                  <img
                    width="400"
                    height="400"
                    src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/58db701349cb48738069e8c912e2b3ac_result-150x150.jpg"
                    alt="Tomato Bruschetta"
                    title="Tomato Bruschetta"
                  />
                </div>
                <div className="header-menu-post-desc">
                  <h4>
                    <span className="menu-title">CARAMEL MACCHIATO</span>
                    <span className="menu-dots"></span>
                    <span className="menu-price">$9.00</span>
                  </h4>
                  <div className="header-menu-text">
                    {" "}
                    Freshly steamed milk with vanilla-flavored syrup{" "}
                  </div>
                </div>
              </div>
              <div className="header-menu-post clearfix">
                <div className="headermenu-img">
                  <img
                    width="400"
                    height="400"
                    src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/f4ec500b34624242b15c2d29e53f3c48_result-150x150.jpg"
                    alt="Tomato Bruschetta"
                    title="Tomato Bruschetta"
                  />
                </div>
                <div className="header-menu-post-desc">
                  <h4>
                    <span className="menu-title">CAFFE MOCHA</span>
                    <span className="menu-dots"></span>
                    <span className="menu-price">$7.00</span>
                  </h4>
                  <div className="header-menu-text">
                    {" "}
                    Full-bodied espresso combined with mocha{" "}
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      )}

      <section className="topSingleBkg topPageBkg">
        <div className="item-content-bkg">
          <div
            className="item-img"
            style={{
              backgroundImage:
                'url("images/top-headers/reservation-image.jpg")',
            }}
          />
          <div className="inner-desc">
            <h1 className="post-title single-post-title">About</h1>
            <span className="post-subtitle"> We love to hear from you </span>
          </div>
        </div>
      </section>

      <section id="home-content-2" className="home-widget pt-0">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              {/* <h2 className="home-subtitle">History</h2>
              <h1 className="home-title margin-b24 title-headline">About Us</h1> */}
              <div className="title-style-1">
                Welcome to Premier Chef Catering,
              </div>
              <p>
                We specialize in catering, distribution, and service provision
                for hotels, international schools, colleges, universities, and a
                variety of other organizations, drawing upon our extensive years
                of industry experience. Our offerings encompass a diverse range
                of cuisine options, coupled with a proficient workforce,
                enabling us to deliver cost-effective meals tailored to a wide
                spectrum of residential and commercial clientele.
              </p>
              <p>
                Wherever possible, we prioritize the utilization of fresh
                ingredients sourced from local vendors, ensuring menus and
                dishes that cater to individuals of all ages. In addition to
                emphasizing the use of fresh ingredients, we place utmost
                importance on customer safety. Our personnel undergo rigorous
                training in food safety and operational cleanliness, ensuring
                that all distributed food items meet stringent safety standards.
              </p>
              <p>
                Beyond the quality of food, we recognize the significance of
                exemplary service. Consequently, we treat our clients as
                esteemed members of our extended family, delivering heartfelt
                and attentive service that acknowledges, enquires, and remembers
                even the minutest details without compromising customer privacy.
              </p>
            </div>
            <div className="col-md-6">
              <img
                className="img-fluid img-feature"
                src="images/home/about-8.jpg"
                loading="lazy"
                alt="about-7"
              />
            </div>
          </div>
        </div>
      </section>

      <section id="home-wine" className="home-widget pt-0">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6">
                  <img
                    className="img-fluid"
                    src="images/home/about-8.jpg"
                    loading="lazy"
                    alt="about-6"
                  />
                </div>
                <div className="col-md-6 padding-tb54 ">
                  {/* <h2 className="home-subtitle">Experience</h2>
                  <h1 className="home-title title-headline margin-b24">Wine</h1> */}
                  <div className="title-style-1">
                    Premium food prepared by Premier Chef
                  </div>
                  <p>
                    When it comes to catering for schools or individuals with
                    special dietary requirements, our team of nutritionists
                    collaborates closely with clients to develop comprehensive
                    menus that prioritize nutrition and consumer safety. We
                    strive to meet all nutritional, flavor, and safety standards
                    while maintaining affordability. For event planning
                    purposes, clients are welcome to share their ideas with us,
                    and we will work collaboratively to bring their dream event
                    to life. We offer an extensive selection of food and
                    beverage options, ranging from buffets and cocktail parties
                    to coffee breaks and set menus. Whether it's a western
                    cuisine wedding banquet, a Chinese birthday celebration, or
                    snacks for seminars and off-site meetings, we are equipped
                    to cater to various event requirements. Additionally, we
                    provide sales stations, culinary demonstrations, and food
                    truck services to enhance the celebratory atmosphere.
                  </p>
                  <p>
                    For those seeking safe and convenient meal options, we offer
                    ready-to-eat food and beverages from our café, including
                    lunch boxes, set menus, snack boxes, creative beverages, and
                    pastries. Whether you require snack boxes for a conference,
                    seminar staff lunch, school excursion, or simply wish to
                    indulge in some afternoon delights, we are at your service
                    to satiate your culinary cravings. Please do not hesitate to
                    contact us for assistance with your dietary needs.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section id="wrap-content" className="page-content">
        <div className="container mx-20">
            <div className="about-content">
                <div>
                    <img src="" alt="" />
                </div>
                <h6>
                    -- We specialize in catering, distribution, and service provision
                    for hotels, international schools, colleges, universities, and a
                    variety of other organizations, drawing upon our extensive years of
                    industry experience. Our offerings encompass a diverse range of
                    cuisine options, coupled with a proficient workforce, enabling us to
                    deliver cost-effective meals tailored to a wide spectrum of
                    residential and commercial clientele.
                    <br />
                    <br />
                    -- Wherever possible, we prioritize the utilization of fresh
                    ingredients sourced from local vendors, ensuring menus and dishes
                    that cater to individuals of all ages. In addition to emphasizing
                    the use of fresh ingredients, we place utmost importance on customer
                    safety. Our personnel undergo rigorous training in food safety and
                    operational cleanliness, ensuring that all distributed food items
                    meet stringent safety standards.
                    <br /> 
                    <br />
                    -- Beyond the quality of food, we recognize the significance of
                    exemplary service. Consequently, we treat our clients as esteemed
                    members of our extended family, delivering heartfelt and attentive
                    service that acknowledges, enquires, and remembers even the minutest
                    details without compromising customer privacy.
                    <br />
                    <br />
                    -- When it comes to catering for schools or individuals with special
                    dietary requirements, our team of nutritionists collaborates closely
                    with clients to develop comprehensive menus that prioritize
                    nutrition and consumer safety. We strive to meet all nutritional,
                    flavor, and safety standards while maintaining affordability. For
                    event planning purposes, clients are welcome to share their ideas
                    with us, and we will work collaboratively to bring their dream event
                    to life. We offer an extensive selection of food and beverage
                    options, ranging from buffets and cocktail parties to coffee breaks
                    and set menus. Whether it's a western cuisine wedding banquet, a
                    Chinese birthday celebration, or snacks for seminars and off-site
                    meetings, we are equipped to cater to various event requirements.
                    Additionally, we provide sales stations, culinary demonstrations,
                    and food truck services to enhance the celebratory atmosphere.
                    <br />
                    <br />
                    -- For those seeking safe and convenient meal options, we offer
                    ready-to-eat food and beverages from our café, including lunch
                    boxes, set menus, snack boxes, creative beverages, and pastries.
                    Whether you require snack boxes for a conference, seminar staff
                    lunch, school excursion, or simply wish to indulge in some afternoon
                    delights, we are at your service to satiate your culinary cravings.
                    Please do not hesitate to contact us for assistance with your
                    dietary needs. <br />
                    <br />
                </h6>
            </div>
        </div>
      </section> */}
      <Footer />
    </div>

    
  );
};

export default About;
