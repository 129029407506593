import React, { useEffect, useState } from 'react'
import "../styles/style.css"
import SpeedDail from './SpeedDail';

const Footer = () => {

    const [isVisible, setIsVisible] = useState(false);

  // Function to check the scroll position
  const checkScrollTop = () => {
    if (!isVisible && window.pageYOffset > 400) {
      setIsVisible(true);
    } else if (isVisible && window.pageYOffset <= 400) {
      setIsVisible(false);
    }
  };

  // Function to scroll to top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);
    return () => window.removeEventListener("scroll", checkScrollTop);
  });
  return (
    <>
    {/* <div style={{backgroundColor:"black", height:"10px"}}></div> */}

<footer>
<div className="container">
  <div className="row alignc">
    <div className="col-md-4">
      <div className="footer-content">
        <h5>ADDRESS:</h5>
        <p>
        Premier Chef Catering Co.,Ltd. <br />
          9/1 Soi Ramkhamhaeng 118 Yeak 46-4 Sapansung, Bangkok 10240
        </p>
      </div>
    </div>
    <div className="col-md-4">
      <div className="footer-content">
        <h5>RESERVATION:</h5>
        <p >
          <a href="tel:+880613876868" style={{color:"white", textDecoration: "none", border: "none"}}>(+88) 0613876868</a>
        </p>
      </div>
    </div>
    <div className="col-md-4">
      <div className="footer-content">
        <h5>OPEN HOURS:</h5>
        <p>
        Weekdays and weekends, our cafe operates from 8:00 AM to 4:00 PM.
        </p>
      </div>
    </div>
  </div>
  <ul className="footer-social">
    <li>
      <a className="social-facebook" href="https://web.facebook.com/premierchefcatering" target="_blank" style={{color:"white"}}>
        <i className="fab fa-facebook-f" />
      </a>
    </li>
    <li>
      <a className="social-twitter" href="#" target="_blank" style={{color:"white"}}>
        <i className="fab fa-line" />
      </a>
    </li>
    <li>
      <a className="social-instagram" href="https://www.instagram.com/premierchefcatering/" target="_blank" style={{color:"white"}}>
        <i className="fab fa-instagram" />
      </a>
    </li>
  </ul>
  <div className="copyright">
     ©Copyright 2024. Designed and developed by Ark Intelligence pvt. ltd.
  </div>
  <div
    className="scrollup"
    style={{ display: isVisible ? "inline" : "none" }}
  >
    {/* Using button element instead of 'a' for accessibility and to prevent default link behavior */}
    <button onClick={scrollToTop} className="scrolltop p-0">
      <i className="fas fa-chevron-up"></i>
    </button>
  </div>
</div>
<SpeedDail/>
</footer>
    </>
  )
}

export default Footer
